import React from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import AppContext from '../../components/context/AppContext';
import RecentPosts from './recentPosts';
import Link from 'next/link';
import axios from 'axios';
import Image from 'next/image';

//prettier-ignore
function Blog() {
  const ArticlesRef = useRef();

  const { setPageNumber , langContext} = useContext(AppContext);

  const optionsContact = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  useEffect(() => {
    let observerContact = new IntersectionObserver((entries) => {
      //console.log("extRef :", entries[0].isIntersecting);
      if (entries[0].isIntersecting) {
        setPageNumber(9);
      }
    }, optionsContact);
    observerContact.observe(ArticlesRef.current);
  }, []);


  const [data, setData] = useState([]);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

 
  const fetchRecentArticles = async () => {
    
    let recentsArticles = [];

    try {
      recentsArticles = await axios.get(
        `${process.env.NEXT_PUBLIC_origin}/api/articles/recentsArticles`
      );

      setData(recentsArticles.data);
      setErr("");
      setLoading(false);
    } catch (err) {
      setData(recentsArticles.data ? recentsArticles.data : []);
      setErr("rejected");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecentArticles();
  }, []);

  return (
    <section
      id="idBlogSection"
      className="mainContent data-scroll-section bg-white min-h-max px-[10vw] pb-[10vh] md:pb-[0vh]  tracking-wide"
      ref={ArticlesRef}
    >
      <div
        id="idBlog"
        className="flex justify-center items-center flex-col   pt-[12vh]  md:pt-[10vh] md:pb-[10vh] gap-12  min-h-max"
      >
        <p
          id="idBlogText"
          className="text-[8vw] sm:text-[8vw] md:text-[4vw]  text-start md:text-center w-full sm:pb-10 text-mainDarkText "
        >
         {langContext == "en"? "LAST ARTICLES": "DÉRNIERS ARTICLES"} 
        </p>

        {err || loading ? (
          <div className=" w-full flex items-center justify-center flex-col">
            <div className="  flex items-center justify-center flex-col w-full py-20 ">
              <Image
                width={75}
                height={75}
                alt="loading"
                src="svg/loading.svg"
                className="   object-cover   "
              />
            </div>
          </div>
        ) : (
          <RecentPosts data={data} err={err} loading={""} />
        )}

        <Link
          id="idBlogButton"
          href={{
            pathname: `${process.env.NEXT_PUBLIC_origin}/articles`,
           
          }}
          className="  bg-mainDarkText  buttonsStyleDark md:hover:bg-mainDarkText/90  text-center   w-44  justify-around px-2 py-4  text-white text-[3vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs tracking-[02px]"
        >
       {langContext == "en"? "READ ARTICLES": "VOIR LES ARTICLES"}    
         
        </Link>
      </div>
    </section>
  );
}

export default Blog;
