import React from 'react';
import {useContext} from 'react';
import AppContext from '../../components/context/AppContext';
import {RiInstagramLine, RiLinkedinFill} from 'react-icons/ri';

import Link from 'next/link';

function Footer() {
	const buttonsStyle = 'hover:cursor-pointer hover:scale-105   transition duration-200';

	const {langContext} = useContext(AppContext);

	return (
		<section
			id='idFooter'
			className='mainContent data-scroll-section bg-backbg px-[10vw] pb-[2vh] tracking-[1px]  text-white'
		>
			<div className=' flex items-end flex-col justify-end w-full py-[2vh]'>
				<div className='flex flex-row items-center justify-end gap-2 '>
					<Link
						href={{
							pathname: `https://linkedin.com`,
						}}
						className={`${buttonsStyle} flex  items-center`}
					>
						<RiLinkedinFill className='w-5 h-5 ' />
						<p className={` flex text-hp8 px-1 pt-1`}>Linkedin</p>
					</Link>
					<Link
						href={{
							pathname: `https://instagram.com`,
						}}
						className={`${buttonsStyle} flex items-center`}
					>
						<RiInstagramLine className='w-5 h-5 ' />
						<p className=' flex text-hp8 m-1 pt-1'>Instagram</p>
					</Link>
				</div>
			</div>
			<div className='flex flex-col md:flex-row pt-[2vh] border-t-[1px] border-white w-full justify-between items-center '>
				<p className='text-hp10'>
					{' '}
					{langContext == 'en' ? 'DEVELOPED AND DESIGNED BY JAMIVIZ' : 'DÉVELOPPÉ ET CONÇU PAR JAMIVIZ'}
				</p>
				<p className='text-hp10'>
					{' '}
					{langContext == 'en'
						? '©2023 JAMIVIZ - All Rights Reserved'
						: '©2023 JAMIVIZ - Tous droits réservés.'}
				</p>
			</div>
		</section>
	);
}

export default Footer;
