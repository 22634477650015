import React, { useState, useEffect, useRef, useContext } from 'react';
import Head from 'next/head';
import Navbar from '../components/layout/Navbar';
import Pourcentage from '../components/layout/Pourcentage';
import AppContext from '../components/context/AppContext';
import Hero from '../components/sectionHero/index';
import SkillsBanner from '../components/sectionSkills';
import Footer from '../components/sectionFooter';
import Visit2 from '../components/sectionVisit/Visit2';

import Contact from '../components/sectionContact';
import Lenis from '@studio-freight/lenis';
import { ScrollTrigger, ScrollToPlugin } from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';

import Brand from '../components/sectionBrands';
import Blog from '../components/sectionBlog';
import Exteriorz from '../components/sectionExtandint/Exteriorz';
import Split from '../components/sectionSplit/index';
import Longtext from '../components/sectionLongText';
import Web from '../components/sectionWeb';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function Index() {
 useEffect(() => {
  if (!ScrollTrigger.isTouch) {
   const lenis = new Lenis({
    duration: 2,
    easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    infinite: false,
   });

   lenis.on('scroll', ScrollTrigger.update);

   gsap.ticker.add(time => {
    lenis.raf(time * 1000);
   });

   gsap.ticker.lagSmoothing(0);

   // Nettoyage lors du démontage
   return () => {
    lenis.off('scroll', ScrollTrigger.update);
   };
  }
 }, []);

 const [preloader, setPreloader] = useState(true); //enable preloader

 useEffect(() => {
  setTimeout(() => {
   setPreloader(false);
  }, 0 * 1000);
 }, []);

 useEffect(() => {
  if (preloader === true) {
  } else {
   //hero timeline
   let t1 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idHero',
     start: '0% top', // position du parent , partie visible dans l'ecran
     end: '80% top',
     scrub: 3,
     // comme toggleActions , si on lui donne un nombre il dur plus longtemps
     // scrub prend true pour etre actif au scrollbar , si on donne un nombre il le prend comme un smoother delay
     //pin: false, // suit un element , si on le met a true il sit le scroll-start , sinon on peut "autre_id"
     markers: false,
    },
   });

   t1.to('#idHeroText1', { y: -75 }, '<');
   t1.to('#idHeroText2', { x: 25 }, '<');
   t1.to('#idHeroText3', { x: -25 }, '<');
   t1.to('#idHeroText4', { x: -200 }, '<');

   t1.to('#idHeroImg', { scale: 1.15 }, '<');
   //  t1.to("#idHeroImg2", { rotation: 50, scale: 1.5,   }, "<");

   t1.to('#idHeroMobileText1', { y: -75 }, '<');
   t1.to('#idHeroMobileText2', { x: 20 }, '<');
   t1.to('#idHeroMobileText3', { x: -20 }, '<');
   t1.to('#idHeroMobileText4', { x: -100 }, '<');

   //stoke timeline
   let t0 = gsap.timeline({
    scrollTrigger: {
     trigger: '#skillsRef',
     start: 'top center',
     end: '120% center',
     scrub: 3,

     markers: false,
    },
   });

   t0.fromTo('#idGroupeStroke path', { opacity: 0 }, { opacity: 0.35, stagger: { amount: 1 }, ease: 'power4.out' });

   //skill1 timeline
   let t2 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idSkillsPartOne',
     start: '50% bottom',
     end: '250% bottom',
     scrub: 3,

     markers: false,
    },
   });

   t2.from('#idSkillsPartOneImgOneDesktop', { y: 150, opacity: 0.75 }, '<');
   t2.from('#idSkillsPartOneImgTwoDesktop', { y: 250, opacity: 0.75 }, '<');

   t2.from('#idTextOneSkillsPartOne', { y: 250, opacity: 0.75 }, '<');
   t2.from('#idTextTwoSkillsPartOne', { y: 250, opacity: 0.75 }, '<');
   t2.from('#idTextThreeSkillsPartOne', { y: 275, opacity: 0.75 }, '<');

   t2.from('#idSkillsPartOneImgOneMobile', { y: 275, opacity: 0.75 }, '<');

   t2.from('#idSkillsPartOneImgTwoMobile', { y: 250, opacity: 0.75 }, '<');

   //skill3 timeline
   let t3 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idSkillsPartTwo',
     start: 'top 100%',
     end: 'bottom 80%',
     scrub: 6,
     markers: false,
    },
   });

   t3.from('#idTextZeroSkillsPartTwo', { x: 350, opacity: 0.75, duration: 10, delay: 2 }, '<');

   t3.from('#idTextOneSkillsPartTwo', { x: 500, opacity: 0.75, duration: 10 }, '<');

   t3.from('#idTextTwoSkillsPartTwo', { x: 500, opacity: 0.75, duration: 10, delay: 3 }, '<');
   t3.from('#idTextThreeSkillsPartTwo', { x: 500, opacity: 0.75, duration: 10, delay: 3 }, '<');

   t3.from('#idTextFourSkillsPartTwo', { y: 550, opacity: 0, duration: 10, delay: 3 }, '<');

   //skill3 timeline
   let t4 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idSkillsPartThree',
     start: 'top 100%',
     end: 'bottom 80%',
     scrub: 2,

     markers: false,
    },
   });

   t4.from('#idSkillsDiscover', { y: 200, opacity: 0 }, '<');
   t4.from('#idSkillsScroll', { y: 200, opacity: 0 }, '<');

   //brand timeline
   let t8 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idBrandMobile',
     start: 'top 100%',
     end: '50% 80%',
     scrub: 4,

     markers: false,
    },
   });

   t8.from('#idBrandMobile', { y: 150, opacity: 0 }, '<');
   t8.from('#idBrandimg1', { y: 150, opacity: 0 }, '<');
   t8.from('#idBrandimg2', { y: 150, opacity: 0 }, '<');
   t8.from('#idBrandimg3', { y: 150, opacity: 0 }, '<');
   t8.from('#idBrandimg4', { y: 150, opacity: 0 }, '<');

   let t10 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idInteractive',
     start: 'top 100%',
     end: '20% 100%',
     scrub: 3,

     markers: false,
    },
   });

   let t10a = gsap.timeline({
    scrollTrigger: {
     trigger: '#splitimg1',
     start: 'top 100%',
     end: '60% 40%',
     scrub: 3,

     markers: false,
    },
   });

   let t10b = gsap.timeline({
    scrollTrigger: {
     trigger: '#splitimg2',
     start: 'top 100%',
     end: '60% 40%',
     scrub: 3,

     markers: false,
    },
   });

   let t10c = gsap.timeline({
    scrollTrigger: {
     trigger: '#splitimg3',
     start: 'top 100%',
     end: '60% 40%',
     scrub: 3,

     markers: false,
    },
   });

   t10.from('#splitGT1', { x: -100 }, '<');
   t10.from('#splitGT2', { x: -100 }, '<');
   t10.from('#splitGT3', { x: -100 }, '<');

   t10a.from('#splitimg1', { y: 80, opacity: 0 }, '<');
   t10a.from('#splitTitre1', { y: 120, opacity: 0 }, '<');
   t10a.from('#splitText1', { y: 140, opacity: 0 }, '<');

   t10b.from('#splitimg2', { y: 80, opacity: 0 }, '<');
   t10b.from('#splitTitre2', { y: 120, opacity: 0 }, '<');
   t10b.from('#splitText2', { y: 140, opacity: 0 }, '<');

   t10c.from('#splitimg3', { y: 80, opacity: 0 }, '<');
   t10c.from('#splitTitre3', { y: 120, opacity: 0 }, '<');
   t10c.from('#splitText3', { y: 140, opacity: 0 }, '<');

   let t11a = gsap.timeline({
    scrollTrigger: {
     trigger: '#slipDektopText1',
     start: 'top 60%',
     end: 'bottom 100%',
     scrub: 4,
     markers: false,
    },
   });

   let t11b = gsap.timeline({
    scrollTrigger: {
     trigger: '#slipDektopText2',
     start: 'top 60%',
     end: 'bottom 100%',
     scrub: 4,
     markers: false,
    },
   });

   let t11c = gsap.timeline({
    scrollTrigger: {
     trigger: '#slipDektopText3',
     start: 'top 60%',
     end: 'bottom 100%',
     scrub: 4,
     markers: false,
    },
   });

   t11a.from('#slipDektopText1', { y: 50, opacity: 0 }, '<');
   t11b.from('#slipDektopText2', { y: 50, opacity: 0 }, '<');
   t11c.from('#slipDektopText3', { y: 50, opacity: 0 }, '<');

   let t12 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idVisit',
     start: '0% 50%',
     end: '80% bottom',
     scrub: 3,

     markers: false,
    },
   });

   t12.to('#carteVisit1', { y: -175, x: 600, rotation: -45 }, '<');
   t12.to('#carteVisit2', { y: -160, x: -600, rotation: 25 }, '<');
   t12.to('#carteVisit3', { y: 0, x: -600, rotation: 60 }, '<');
   t12.to('#carteVisit4', { y: 0, x: 600, rotation: -30 }, '<');

   let t13 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idVisit',
     start: '0% 50%',
     end: '80% bottom',
     scrub: 2,

     markers: false,
    },
   });

   t13.fromTo('#carteVisitMobile1', { y: 100, opacity: 0.5 }, { y: 0, opacity: 1 }, '<');

   let t14 = gsap.timeline({
    scrollTrigger: {
     trigger: '#idLongText',
     start: '-200% top',
     end: '400% bottom',
     scrub: 4,
     markers: false,
    },
   });

   t14.fromTo('#idLongText4', { x: ScrollTrigger.isTouch ? -500 : 1200 }, { x: ScrollTrigger.isTouch ? -1200 : -300 }, '<');
   t14.fromTo('#idLongText5', { x: ScrollTrigger.isTouch ? -1000 : -1200 }, { x: ScrollTrigger.isTouch ? -300 : 300 }, '<');
  }
 }, [preloader]);

 return (
  <div className={`  tracking-wide bg-backbg text-white w-full font-poppins `}>
   <Head>
    <title>JAMIVIZ - Développeur Web 3D à Montreal</title>

    <meta property='og:title' content='JAMIVIZ - Portfolio' />
    <meta name='description' content='Jamiviz est un développeur Web 3D freelance à Montreal - Quebec  , spécialisé en programmation et développement  de sites web 3D.'></meta>
    <meta property='og:description' content='Jamiviz est un développeur Web 3D freelance à Montreal - Quebec , spécialisé en programmation et développement  de sites web 3D. ' />
    <link rel='alternate' href='https://jamiviz.com/' hrefLang='fr' />
   </Head>

   <Navbar className='z-50' />
   <Pourcentage />
   <Hero />
   <SkillsBanner />
   <Web />
   <Exteriorz />
   <Split />
   <Longtext />
   <Visit2 />
   <Brand />
   <Blog />
   <Contact />
   <Footer />
   {/* 
          
         
          */}
  </div>
 );
}

export default Index;
