import { useState, useEffect, useRef, useContext } from 'react';
import AppContext from '../../components/context/AppContext';
import Image from 'next/image';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper';
import Link from 'next/link';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Visit2() {
 const { setPageNumber } = useContext(AppContext);
 const { langContext } = useContext(AppContext);
 const VisitRef = useRef();
 const swiperRef = useRef(null);

 const optionsVisit = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
 };

 useEffect(() => {
  let observerVisit = new IntersectionObserver(entries => {
   //console.log("extRef :", entries[0].isIntersecting);
   if (entries[0].isIntersecting) {
    setPageNumber(8);
   }
  }, optionsVisit);
  observerVisit.observe(VisitRef.current);
 }, []);

 return (
  <div id='idVisit' className='  min-h-min md:h-[4000px]  flex items-start justify-center max-w-[100vw] relative bg-white md:bg-backbg  md:pb-32 md:pt-20 idVisitIndex font-poppins'>
   <div className='hidden md:flex bg-blue-00 min-h-[400px] h-screen w-full  sticky top-0    overflow-hidden ' ref={VisitRef}>
    <div className='text-backbg bg-red-500x carteText absolute   flex items-center justify-center  text-center max-w-2xl flex-col'>
     <p className=' text-hp3 w-full text-mainDarkText md:text-main  leading-[6rem]  '>{langContext == 'en' ? 'Realistic 3D WEB' : 'WEB 3D Réaliste'}</p>
     <p className='xg-red-800 flex items-center gap-[1rem] justify-center text-hp9 pb-8 text-white tracking-[0.1vw] xg-blue-800 w-full  font-poppins   '>
      {langContext == 'en' ? '360° RENDERING' : 'RENDU 360°'}
      <span className='w-[30px] h-[2px]  flex bg-white'></span>
      <span className='text-hp9 '>3DSMAX & CORONA </span>
     </p>
     <p className='text-hp10 text-backbg md:text-white max-w-xl pb-2 text-center  pt-2'>
      {langContext == 'en'
       ? `Specialized in realistic 360-degree web 3D, I create immersive experiences with a focus 
              on realistic textures, enhancing human interaction in online virtual environments.                          `
       : `Spécialisé en 3D web réaliste à 360 degrés, je crée des expériences immersives axées sur des textures réalistes, 
              enrichissant ainsi l'interaction humaine dans des environnements virtuels. `}
     </p>
     <p className='text-hp10 text-backbg md:text-white max-w-xl pb-8 text-center  '>
      {langContext == 'en'
       ? `
              Each project represents an opportunity for innovation in the field of 3D web development, 
              aligning with current SEO trends to maximize visibility and user engagement. My approach 
              combines visual creativity with technical expertise to deliver captivating and high-performing 3D websites.              `
       : ` Chaque projet représente une opportunité 
              d'innovation dans le domaine du développement web 3D, en harmonie avec les tendances SEO actuelles pour maximiser la visibilité 
              et l'engagement des utilisateurs. Mon approche conjugue la créativité visuelle à une expertise technique solide pour offrir des
               sites web 3D captivants et performants.`}
     </p>

     <div className='flex flex-col xg-blue-800 w-full md:w-1/2 justify-center  items-center text-center max-w-full g-blue-900 pt-8  '>
      <Link
       href={{
        pathname: '',
       }}
       className=' hidden  bg-mainDarkText buttonsStyleDark md:hover:bg-mainDarkText/80 w-44  justify-around px-2 py-2 my-2 text-white text-hp11'
      >
       {langContext == 'en' ? 'Villa Project' : 'Projet Villa'}
      </Link>
      <Link
       href={{
        pathname: 'https://jamimedia.vercel.app/Asdfsdf458sf/option1.html',
       }}
       className='   bg-mainDarkText  md:hover:bg-mainDarkText/80 buttonsStyleDark  w-44 justify-around px-2 py-2 my-2 text-white text-hp11'
      >
       {langContext == 'en' ? 'Studio T1 Project' : 'Projet Studio T1'}
      </Link>
      <Link
       href={{
        pathname: 'https://jamimedia.vercel.app/Cdsf5sdf45/index.htm',
       }}
       className='   bg-mainDarkText  md:hover:bg-mainDarkText/80 buttonsStyleDark   w-44 justify-around px-2 py-2  my-2  text-white text-hp11'
      >
       {langContext == 'en' ? 'Apartment Project' : 'Projet Appart'}
      </Link>
     </div>
    </div>

    <div id='carteVisit1' className=' hidden md:flex w-[20vw] max-w-[500px] min-w-[200px]  absolute carteVisit1 shadow-sm '>
     <Image width={500} height={500} quality={20} alt='developpeur web montreal' src='/img/5.jpg' className='     object-cover  h-full   w-full  ' />
    </div>
    <div id='carteVisit2' className=' hidden md:flex w-[20vw] max-w-[500px] min-w-[200px]   absolute carteVisit2  shadow-sm'>
     <Image width={500} height={500} quality={20} alt='developpeur informatique' src='/img/8.jpg' className='     object-cover  h-full   w-full  ' />
    </div>
    <div id='carteVisit3' className=' hidden md:flex w-[16vw] max-w-[500px]  min-w-[200px]  absolute carteVisit3  shadow-sm'>
     <Image width={500} height={500} quality={20} alt='création site web montreal' src='/img/3.jpg' className='     object-cover  h-full   w-full  ' />
    </div>
    <div id='carteVisit4' className=' hidden md:flex w-[16vw] max-w-[500px] min-w-[200px]   absolute carteVisit4  shadow-sm'>
     <Image width={500} height={500} priority quality={60} alt='creation site web montreal' src='/img/4.jpg' className='     object-cover  h-full   w-full  ' />
    </div>
   </div>

   <div className='  carteVisitMobileContainer  min-h-[1000px] flex flex-col md:hidden bg-blue-00  h-screen w-full  py-24  items-center justify-center gap-10 ' ref={VisitRef}>
    <div id='carteVisitMobile1' className='text-backbg      flex items-center justify-center g-red-500   flex-col   px-12 w-full  gap-8 '>
     <div className=' text-backbg w-full  flex flex-col justify-center text-start items-center'>
      <h2 className=' text-hp3  w-full text-mainDarkText   '>{langContext == 'en' ? 'Realistic 3D WEB' : 'WEB 3D Réaliste'}</h2>
      <p className='xg-red-800 flex items-center gap-[1rem] justify-start text-hp9 pb-8  tracking-[0.1vw] xg-blue-800 w-full  font-poppins   '>
       {langContext == 'en' ? '360° RENDERING' : 'RENDU 360°'}
       <span className='w-[30px] h-[2px]  flex bg-backbg'></span>
       <span className='text-hp9 '>3DSMAX & CORONA </span>
      </p>
      <p className='text-sm text-backbg   pt-2'>
       {langContext == 'en'
        ? `Specialized in realistic 360-degree web 3D, I create immersive experiences that focus on
                 authentic textures, thereby enhancing human interaction in online virtual environments.                           `
        : `Spécialisé dans la 3D web réaliste à 360 degrés, je développe 
                des expériences immersives qui mettent l'accent sur des textures authentiques,
                 améliorant ainsi l'interaction humaine dans des environnements virtuels en ligne. `}
      </p>
      <p className='text-sm text-backbg   pt-2'>
       {langContext == 'en'
        ? ` 
                Every project is an opportunity to innovate in the field of 3D web development, aligning with current SEO trends to optimize user visibility and engagement. My approach combines visual 
                creativity with technical expertise to create 3D websites that are both captivating and high-performing.`
        : `Chaque projet est une occasion d'innover dans le domaine du développement web 3D, en alignant avec les tendances SEO pour optimiser la visibilité et l'engagement des utilisateurs. 
                Mon approche marie créativité visuelle et expertise technique pour créer des sites web 3D à la fois captivants et performants.                
               `}
      </p>
     </div>
     <Swiper
      pagination={{
       dynamicBullets: true,
      }}
      spaceBetween={8}
      loop={true}
      slidesPerView='auto'
      modules={[Pagination]}
      style={{
       '--swiper-pagination-color': '#ffffff',
       '--swiper-pagination-bullet-width': '10px',
       '--swiper-pagination-bullet-height': '10px',
      }}
     >
      <SwiperSlide className='  flex w-full h-full items-center justify-center'>
       <div className='w-full h-full '>
        <Image width={500} height={500} quality={20} alt='developpeur web montreal' src='/img/8.jpg' className='     object-cover w-full   z-50   h-full   items-center justify-center' />
       </div>
      </SwiperSlide>
      <SwiperSlide className='  flex w-full h-full items-center justify-center'>
       <div className='w-full h-full '>
        <Image width={500} height={500} quality={20} alt='creation site web Montreal' src='/img/5.jpg' className='     object-cover w-full   z-50   h-full   items-center justify-center' />
       </div>
      </SwiperSlide>
      <SwiperSlide className='  flex w-full h-full items-center justify-center'>
       <div className='w-full h-full '>
        <Image width={500} height={500} quality={20} alt='freelance web montreal' src='/img/3.jpg' className='     object-cover w-full   z-50   h-full   items-center justify-center' />
       </div>
      </SwiperSlide>
      <SwiperSlide className='  flex w-full h-full items-center justify-center'>
       <div className='w-full h-full '>
        <Image width={500} height={500} quality={20} alt='developpement web montreal' src='/img/4.jpg' className='     object-cover w-full   z-50   h-full   items-center justify-center' />
       </div>
      </SwiperSlide>
     </Swiper>
     <div className='flex flex-col xg-blue-800 w-full  justify-center  items-center  max-w-full g-blue-900   '>
      <Link
       href={{
        pathname: '',
       }}
       className='  hidden bg-mainDarkText  buttonsStyleDark md:hover:bg-mainDarkText/90  text-center   w-44  justify-around px-2 py-4 my-2 text-white text-[2.5vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs tracking-[02px]'
      >
       {langContext == 'en' ? 'Villa Project' : 'Projet Villa'}
      </Link>
      <Link
       href={{
        pathname: 'https://jamimedia.vercel.app/Asdfsdf458sf/option1.html',
       }}
       className='   bg-mainDarkText  buttonsStyleDark md:hover:bg-mainDarkText/90  text-center   w-44  justify-around px-2 py-4 my-2 text-white text-[2.5vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs tracking-[02px]'
      >
       {langContext == 'en' ? 'Studio T1 Project' : 'Projet Studio T1'}
      </Link>
      <Link
       href={{
        pathname: 'https://jamimedia.vercel.app/Cdsf5sdf45/index.htm',
       }}
       className='   bg-mainDarkText  buttonsStyleDark md:hover:bg-mainDarkText/90  text-center   w-44  justify-around px-2 py-4 my-2 text-white text-[2.5vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs tracking-[02px]'
      >
       {langContext == 'en' ? 'Apartment Project' : 'Projet Appart'}
      </Link>
     </div>
    </div>
   </div>
  </div>
 );
}

export default Visit2;
/* 
 </div>
*/
