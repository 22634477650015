import React from 'react';

function Icons({ icon, text, size }) {
 return (
  <div key={text} className='flex stackpartsOne hover:scale-120 md:hover:text-white cur cursor-pointer text-main items-center transition-all duration-300 ease-in-out   flex-cols justify-start leading-[1rem] gap-[0.2rem] md:gap-[0.1rem] bg-red-600x'>
   <div className={` w-[2rem] md:w-[4rem]  flex items-center justify-center text-center   `}>{icon}</div>
   <p className='uppercase  text-[10px] xs:text-[12px] md:text-[16px]'>{text}</p>
  </div>
 );
}

export default Icons;
