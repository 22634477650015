import { useEffect, useState } from 'react';

const useIsMobile = () => {
 const [isMobile, setIsMobile] = useState(false);

 const checkDimensions = () => {
  if (window.matchMedia('(max-width: 767px)').matches) {
   setIsMobile(true);
  } else {
   setIsMobile(false);
  }
 };

 useEffect(() => {
  checkDimensions();
  window.addEventListener('resize', checkDimensions);
  return () => {
   window.removeEventListener('resize', checkDimensions);
  };
 }, []);

 console.log('isMobile :', isMobile);

 return isMobile;
};

export default useIsMobile;
