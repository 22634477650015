import React from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import AppContext from '../../components/context/AppContext';
import Image from 'next/image';
import DOMPurify from 'dompurify';
import axios from 'axios';

function Contact() {
 const ContactRef = useRef();

 const { setPageNumber, langContext } = useContext(AppContext);

 const optionsContact = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
 };

 useEffect(() => {
  let observerContact = new IntersectionObserver(entries => {
   //console.log("extRef :", entries[0].isIntersecting);
   if (entries[0].isIntersecting) {
    setPageNumber(10);
   }
  }, optionsContact);
  observerContact.observe(ContactRef.current);
 }, []);

 const buttonsStyle = 'hover:cursor-pointer hover:scale-105 hover:opacity-80   transition duration-200';

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [message, setMessage] = useState('');
 const [subject, setSubject] = useState('');
 const [data, setData] = useState('');
 const [status, setStatus] = useState('');

 const handleName = e => {
  setName(DOMPurify.sanitize(e.target.value));
  setStatus('');
 };

 const handleEmail = e => {
  setEmail(DOMPurify.sanitize(e.target.value));
  setStatus('');
 };

 const handleSubject = e => {
  setSubject(DOMPurify.sanitize(e.target.value));
  setStatus('');
 };

 const handleMessage = e => {
  setMessage(DOMPurify.sanitize(e.target.value));
  setStatus('');
 };

 const handleSubmit = async e => {
  e.preventDefault();
  if (name == '' || email == '' || message == '' || subject == '') {
   setStatus('');
  }

  setName('');
  setEmail('');
  setMessage('');
  setSubject('');

  try {
   const response = await axios.post('/api/contact', {
    name,
    email,
    message,
    subject,
   });

   if (response.data.status === 200) setStatus('Message sent successfully !');
   else setStatus('Please try again later !');
  } catch (e) {
   setStatus(' Please try again later !');
  }
 };

 useEffect(() => {
  setData({ name, email, message });
 }, [name, email, message]);

 return (
  <section className='mainContent data-scroll-section bg-backbg min-h-max px-[10vw] pb-[4vh] tracking-[1px] flex' ref={ContactRef}>
   <div className='flex justify-center items-start flex-col  md:w-1/2   min-h-max'>
    <div className=' pt-[6vh] text-white '>
     <h4 className='text-[9vw] sm:text-[8vw] md:text-[3.5vw] text-main '> {langContext == 'en' ? 'Have an idea ?' : 'Vous avez une idée ?'}</h4>
     <p className='text-hp10 font- poppins py-4 capitalize  '>
      {langContext == 'en' ? 'Fill IN THE FIELD BELLOW AND SEND ME A MESSAGE OR CONTACT ME AT ' : 'REMPLISSEZ LE CHAMPS CI DESSOUS ET ENVOYEZ-MOI UN MESSAGE  '}
      <span className='text-main text-hp9 '>→</span>
     </p>
    </div>
    <div className='w-full  '>
     <form id='formContact' className='flex flex-col items-start justify-center max-w-lg gap-2' method='post' onSubmit={handleSubmit}>
      <div className='flex flex-row  w-full items-center justify-between gap-4 '>
       <div className='border-b-[1px] pt-[3vh] w-full   max-w-lg'>
        <input required maxLength='30' type='text' name='name' placeholder={langContext == 'en' ? 'Name' : 'Nom'} className='bg-backbg text-white text-hp8  w-full my-input' onChange={e => handleName(e)} value={name} />
       </div>
       <div className='border-b-[1px] pt-[3vh] w-full   max-w-lg'>
        <input required maxLength='40' type='email' name='email' placeholder={langContext == 'en' ? 'Email' : 'Email'} className='bg-backbg text-white text-hp8 w-full my-input ' onChange={e => handleEmail(e)} value={email} />
       </div>
      </div>

      <div className='border-b-[1px] pt-[5vh] w-full   max-w-lg'>
       <input required maxLength='100' type='text' name='subject' placeholder={langContext == 'en' ? 'Subject' : 'Sujet'} className='bg-backbg  text-white text-hp8 w-full my-input ' onChange={e => handleSubject(e)} value={subject} />
      </div>

      <div className='border-b-[1px] pt-[5vh] w-full   max-w-lg'>
       <input required maxLength='150' type='text' name='message' placeholder={langContext == 'en' ? 'Informations about your project' : 'Informations à propos de votre projet'} className='bg-backbg text-white text-hp8 w-full my-input' onChange={e => handleMessage(e)} value={message} />
      </div>

      <div className=' pt-[3vh] flex items-center'>
       <button disabled={name == '' || subject == '' || email == '' || message == '' ? true : false} type='submit' value='SUBMIT' className={` ${name == '' || subject == '' || email == '' || message == '' ? 'bg-mainDarkText' : 'bg-mainDarkText/80'} hover:bg-mainDarkText/80  text-center   w-44  justify-around px-2 py-4 my-2  text-[2.5vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs tracking-[2px] text-white ${buttonsStyle}`}>
        {langContext == 'en' ? 'SUBMIT' : 'ENVOYER'}
       </button>
       <div className='text-hp9 text-mainDarkText'>{status}</div>
      </div>
     </form>
    </div>
   </div>
   <div className=' items-end justify-end text-5xl text-white hidden g-red-500 md:flex md:w-1/2'>
    <Image width={300} height={300} quality={20} alt='developpeur web Montreal' src='/brand/signature2.png' className='    object-cover   w-1/3  ' />
   </div>
  </section>
 );
}

export default Contact;
