import React, { useState, useContext, useEffect } from 'react';
import Link from 'next/link';
import { IoIosMenu } from 'react-icons/io';
import { RiInstagramLine, RiLinkedinFill, RiCloseLine } from 'react-icons/ri';
import AppContext from '../../components/context/AppContext';
import { useRouter } from 'next/router';
import { MdLanguage } from 'react-icons/md';

const Navbar = () => {
 const { pageNumber, langContext, setLangContext } = useContext(AppContext);

 const [navActive, setNavActive] = useState(false);
 const [activeSection, setActiveSection] = useState('idHero');
 const [rotateMenuButton, setRotateMenuButton] = useState('rotate-0');

 const [activeLang, setActiveLang] = useState('fr');

 const langHandler = e => {
  if (activeLang == 'en') {
   setActiveLang('fr');
   setLangContext('fr');
  } else {
   setActiveLang('en');
   setLangContext('en');
  }
 };

 const router = useRouter();
 const currentPath = router.asPath;

 const handleText = e => {
  setNavActive(false);

  if (e.target.text === 'HOME' || e.target.text === 'ACCUEIL') {
   setActiveSection('idHero');
   document.getElementById('idHero').scrollIntoView({ behavior: 'smooth' });
  }

  if (e.target.text === 'A PROPOS' || e.target.text === 'ABOUT ME') {
   setActiveSection('skillsRef');
   document.getElementById('skillsRef').scrollIntoView({ behavior: 'smooth' });
  }

  if (e.target.text === '3D RENDERING') {
   setActiveSection('idExterior');
   document.getElementById('idExterior').scrollIntoView({ behavior: 'smooth' });
  }

  if (e.target.text === 'INTERACTIVE 3D') {
   setActiveSection('idInteractive');
   document.getElementById('idInteractive').scrollIntoView({ behavior: 'smooth' });
  }

  if (e.target.text === '360° ARCHVIZ') {
   setActiveSection('idVisit');
   document.getElementById('idVisit').scrollIntoView({ behavior: 'smooth' });
  }
  if (e.target.text === 'BLOG') {
   setActiveSection('idBlog');
   document.getElementById('idBlog').scrollIntoView({ behavior: 'smooth' });
  }

  if (e.target.text === 'CONTACT') {
   setActiveSection('idFooter');
   document.getElementById('idFooter').scrollIntoView({ behavior: 'smooth' });
  }
 };

 useEffect(() => {
  if (pageNumber === 1) setActiveSection('idHero');
  if (pageNumber === 2) setActiveSection('skillsRef');

  if (pageNumber === 5) setActiveSection('idExterior');
  if (pageNumber === 6) setActiveSection('idInteractive');
  if (pageNumber === 7) setActiveSection('idVisit');
  if (pageNumber === 8) setActiveSection('idBlog');
  if (pageNumber === 9) setActiveSection('idFooter');
 }, [pageNumber]);

 const setRotateMenuButtontoZero = () => {
  setRotateMenuButton('rotate-90');
 };
 const setRotateMenuButtontoDegre = () => {
  setRotateMenuButton('rotate-0');
 };

 useEffect(() => {
  if (navActive) {
   document.documentElement.style.setProperty('--navStyle', `normal`);
  } else {
   setTimeout(() => {
    document.documentElement.style.setProperty('--navStyle', `difference`);
   }, 0.2 * 1000);
  }
 }, [navActive]);

 const handleScroll = () => {
  const navbar = document.getElementById('idNavbar');
  if (!navbar) return;
  if (window.scrollY < 100) {
   navbar.classList.remove('navBlendMode');
  } else {
   navbar.classList.add('navBlendMode');
  }
 };

 useEffect(() => {
  // Check if window is defined
  if (typeof window !== 'undefined') {
   document.addEventListener('scroll', handleScroll);
  }

  return () => {
   if (typeof window !== 'undefined') {
    document.removeEventListener('scroll', handleScroll);
   }
  };
 }, []);

 const buttonsStyle = 'hover:cursor-pointer hover:scale-105   transition duration-200';

 return currentPath === '/' || currentPath === '/#3drendering' || currentPath === '/#home' || currentPath === '/#aboutme' || currentPath === '/#contact' || currentPath === '/#360archviz' || currentPath === '/#blog' || currentPath === '/#interactive3d' ? (
  <nav id='idNavbar' className={` mainContent data-scroll-section fixed grid grid-rows-2 mx-auto  font- poppins h-[10vh]  tracking-wide  w-full navIndexTwo`}>
   <div
    className='flex flex-row    px-[6vw] pt-[5vh] 
      xs:px-[6vw] xs:pt-[5vh]  sm:px-[4vw] sm:pt-[5vh] md:px-[4vw] md:pt-[5vh]
      lg:px-[2vw] lg:pt-[5vh] xl:px-[1.75vw] xl:pt-[4vh] bl:px-[2vw] bl:pt-[4vh]
      items-center justify-between w-full navIndexOne '
   >
    <p
     className={`text-[6vw] xs:text-[6.5vw] sm:text-[5vw] md:text-[4.5vw]  lg:text-[4vw] xl:text-[3.5vw] bl:text-[3vw] text-white  
        navIndexOne   font-poppins -tracking-[3px] md:-tracking-[4px] `}
    >
     JAMIVIZ
    </p>

    <div className='flex flex-row xg-red-500 items-center justify-center gap-4 md:gap-10'>
     {!navActive && (
      <div onClick={e => langHandler(e)} className='flex transition-all duration-300 gap-1 b-gray-400/30 rounded-md md:px-0 px-4 text-hp8  items-center'>
       <MdLanguage />
       <p className={`${activeLang == 'fr' ? 'text-white' : 'text-gray-400'}  transition-all delay-100 cursor-pointer`}>Fr</p>
       <p>|</p>
       <p className={`${activeLang == 'en' ? 'text-white' : 'text-gray-400'} transition-all delay-100  cursor-pointer`}>En</p>
      </div>
     )}
     <button
      aria-label='open menu'
      role='button'
      id='menuButton'
      onMouseEnter={() => setRotateMenuButtontoZero()}
      onMouseLeave={() => setRotateMenuButtontoDegre()}
      className={`   ${rotateMenuButton} transition-all ease-in-out  duration-500`}
      open
      onClick={() => {
       setRotateMenuButtontoDegre();
       navActive === true ? setNavActive(false) : setNavActive(true);
      }}
     >
      <IoIosMenu
       className={`
        w-[8vw] h-[8vw]
        xs:w-[6vw] xs:h-[6vw]     
        sm:w-[5vw] sm:h-[5vw]       
        md:w-[4vw] md:h-[4vw]               
        lg:w-[2.5vw] lg:h-[2.5vw]  
        xl:w-[2w] xl:h-[2vw]
        bl:w-[2vw] bl:h-[2vw]         
        ${navActive === true ? 'hidden' : 'block'}
           fill-current  text-white  `}
      />
      <RiCloseLine
       className={`
           w-[8vw] h-[8vw]
           xs:w-[6vw] xs:h-[6vw]     
           sm:w-[5vw] sm:h-[5vw]       
           md:w-[4vw] md:h-[4vw]               
           lg:w-[3.5vw] lg:h-[3.5vw]  
           xl:w-[2vw] xl:h-[2vw]
           bl:w-[2vw] bl:h-[2vw]  
           ${navActive === true ? 'block' : 'hidden'}      
        fill-current  text-white`}
      />
     </button>
    </div>
   </div>

   <div
    className={`  ${navActive === true ? 'opacity-100 visible pointer-events-auto   scale-y-100' : 'opacity-0 invisible pointer-events-none  scale-y-0'} 
      opacity-100 visible pointer-events-auto scale-y-100
       grid  transform  origin-top bg-black/90  text-white transition-all mix-blend-screen
       duration-500 backdrop-blur-lg absolute  pt-20 right-0 navIndexTwo w-2/4 sm:w-2/5 lg:w-1/4 min-h-screen
       
       pr-[10vw]  xs:pr-[8vw]   sm:pr-[6vw]  md:pr-[6vw]          lg:pr-[6vw]  xl:pr-[4vw]  
       `}
   >
    <ul
     className='flex  flex-col text-end  font-poppins  gap-y-2 lg:gap-y-6 text-[4vw] xs:text-xl
     
    '
    >
     <li className={`${activeSection === 'idHero' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#home' className='    hover:text-primary  hover:text-white/90' onClick={e => handleText(e)}>
       {activeLang == 'en' ? 'HOME' : 'ACCUEIL'}
      </Link>
     </li>
     <li className={`${activeSection === 'skillsRef' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#aboutme' className=' hover:text-primary  hover:text-white/90' onClick={e => handleText(e)}>
       {activeLang == 'en' ? 'ABOUT ME' : 'A PROPOS'}
      </Link>
     </li>
     <li className={`${activeSection === 'idExterior' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#3drendering' className='   hover:text-primary  hover:text-white/90' onClick={e => handleText(e)}>
       3D RENDERING
      </Link>
     </li>

     <li className={`${activeSection === 'idInteractive' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#interactive3d' className='   hover:text-primary  hover:text-white/90' onClick={e => handleText(e)}>
       INTERACTIVE 3D
      </Link>
     </li>
     <li className={`${activeSection === 'idVisit' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#360archviz' className='   hover:text-primary  hover:text-white/90' onClick={e => handleText(e)}>
       360° ARCHVIZ
      </Link>
     </li>
     <li className={`${activeSection === 'idBlog' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#blog' className='   hover:text-primary  hover:text-white/90' onClick={e => handleText(e)}>
       BLOG
      </Link>
     </li>

     <li className={`${activeSection === 'idFooter' ? 'text-white font-bold underline' : '  text-white/70 font-light'}`}>
      <Link href='#contact' className='    hover:text-primary  hover:text-white/90 ' onClick={e => handleText(e)}>
       CONTACT
      </Link>
     </li>
    </ul>
    <ul
     className='flex  flex-col text-end  font-poppins  gap-y-2 lg:gap-y-6 text-[4vw] xs:text-xl 
     
    '
    ></ul>

    <div className='flex flex-row items-center justify-end gap-2 text-center'>
     <Link
      href={{
       pathname: `https://linkedin.com`,
      }}
      className='flex hover:text-white/90 items-center'
     >
      <RiLinkedinFill className='w-6 h-6 lg:w-6 lg:h-6 m-1 pb-1' />
      <p className=' hidden md:flex text-hp8  items-center justify-center'>Linkedin</p>
     </Link>
     <Link
      href={{
       pathname: `https://instagram.com`,
      }}
      className='flex hover:text-white/90  items-center'
     >
      <RiInstagramLine className='w-5 h-5 lg:w-5 lg:h-5 text-center' />
      <p className=' hidden md:flex text-hp8 m-1'>Instagram</p>
     </Link>
    </div>
   </div>
  </nav>
 ) : (
  <nav></nav>
 );
};

export default Navbar;
