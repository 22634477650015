const removeSpaceTrimLow = (string) => {
  let removeSpace = string.replace(/\s+/g, "");
  let trimedString = removeSpace.trim();
  let lowerString = trimedString.toLowerCase();
  return lowerString;
};

const replaceSpaceTrimLow = (string) => {
  let replaceSpace = string.replace(/\s+/g, "-");
  let trimedString = replaceSpace.trim();
  let lowerString = trimedString.toLowerCase();
  return lowerString;
};

const capitalize = (str) => {
  if (str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }
};

const listing_url_concatenate = (reference, category, type, city, district) => {
  let cityWitouthSpace = replaceSpaceTrimLow(city);
  let districtWitouthSpace = replaceSpaceTrimLow(district);
  const result =
    category +
    "-" +
    type +
    "-a-" +
    cityWitouthSpace +
    "-" +
    districtWitouthSpace +
    "-" +
    reference;
  return result;
};

const article_url_concatenate = (unique, article_title) => {
  let articletitle = replaceSpaceTrimLow(article_title);

  const result = articletitle + "-" + unique;
  return result;
};

function truncateText(input, length) {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
}

export {
  truncateText,
  removeSpaceTrimLow,
  replaceSpaceTrimLow,
  capitalize,
  listing_url_concatenate,
  article_url_concatenate,
};
