import React from "react";

import { truncateText } from "../../utils/utilFunctions";
import Image from "next/image";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import Link from "next/link";
//prettier-ignore
function RecentPosts(props) {


  return (
    <div className="flex flex-col  md:flex-row  items-center md:items-start justify-center  w-full    gap-12 "  >
      

      { props.err == "" &&
      props.data &&
      props.data.recentsArticles_data.length != 0
        ? props.data.recentsArticles_data.map(( element,index) => {
         
          if (index == 0 || index == 1 ||index == 2 )
            return (
              <Link
              href={{
                pathname: `${process.env.NEXT_PUBLIC_origin}/articles/${element.articleUrl}`,
              
              }}
              
                key={element.title}
               
                className="  gap-6 flex w-full md:w-1/3  h-max   max-w-[400px] flex-col buttonsStyle"
              >
               
               <div className="flex items-center justify-center  flex-col max-w-[400px]  h-[300px] ">
                    <Image
                      width={800}
                      height={800}
                      quality={20}
                      alt={`${truncateText(element.title, 80)}`}
                      src={element.images}
                      className=" object-cover w-full h-full"
                    />
                </div>

                  <div className="w-full flex flex-col items-start justify-start text-xs gap-2 ">
                  <div className="w-full flex flex-row items-center justify-start text-xs">
                      <p className="text-backbg/60 capitalize">
                        {element.tags[0]}
                      </p>
                      <span className="w-6 h-[1px] mx-2 border border-1 border-black/30"></span>
                      <p className="text-backbg/60 capitalize">
                        {format(new Date(element.updatedAt), "MMM d, yyyy", {
                          locale: fr,
                        })}
                      </p>
                    </div>

                      <p className="text-backbg/80 text-sm">
                        
                      {truncateText(element.title, 80)}
                    </p>
                     
                    </div>
                  
              </Link>
            );
          })
        : ""}
    </div>
  );
}

export default RecentPosts;
