import React from 'react';

function Longtext() {
	return (
		<div
			id='idLongText'
			className=' bg-red-00 font-bold    px-40  w-full md:px-0  md:flex items-start md:items-center justify-center flex-col   overflow-hidden '
		>
			<div className='text-start items-start flex justify-start flex-col gap-2 py-20 md:py-64 '>
				<p
					id='idLongText4'
					className='uppercase text-5xl sm:text-6xl  md:text-7xl  lg:text-9xl  truncate pt-3 text-main md:text-white'
				>
					Explorez le futur du développement web Explorez le futur du développement web
				</p>
				<p
					id='idLongText5'
					className='uppercase text-5xl sm:text-6xl  md:text-7xl  lg:text-9xl truncate pt-3 md:text-main text-white'
				>
					Découvrez les perspectives de demain Découvrez les perspectives de demain
				</p>
			</div>
		</div>
	);
}

export default Longtext;
