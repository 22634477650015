import React, { useState, useEffect, useRef, useContext } from 'react';
import Image from 'next/image';
import AppContext from '../context/AppContext';
import Link from 'next/link';
function Hero({}) {
 const { setPageNumber, langContext } = useContext(AppContext);

 const [currentSlider, setCurentSlider] = useState(0);
 const [aboveText, setAboveText] = useState(0);
 const [bellowText, setBellowText] = useState(0);

 useEffect(() => {
  if (currentSlider == 0) {
   setAboveText('Freeplace');
   setBellowText('3D Exterior Rendering');
  }
  if (currentSlider == 1) {
   setAboveText('The House');
   setBellowText('3D Interir Rendering');
  }
  if (currentSlider == 2) {
   setAboveText('Train Garden');
   setBellowText('3D Exterior Rendering');
  }
 }, [currentSlider]);

 const heroRef = useRef();

 const optionsHero = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
 };

 useEffect(() => {
  let observerHero = new IntersectionObserver(entries => {
   //  //console.log("heroRef :", entries[0].isIntersecting);
   if (entries[0].isIntersecting) setPageNumber(1);
  }, optionsHero);
  observerHero.observe(heroRef.current);
 }, []);

 const sizeTextDesktop = langContext === 'en' ? ' md:text-[4.5vw]  lg:text-[5.05vw]  ' : ' md:text-[3.5vw]  lg:text-[4.1vw]  ';
 const sizeTextMobile = langContext === 'en' ? 'text-[12.2vw]  ' : ' text-[10vw] ';

 return (
  <section id='idHero' ref={heroRef} className='  min-h-[600px] h-screen relative  flex mainContent items-center  justify-center  font-poppins'>
   <div className='h-full flex relative w-full  md:w-1/2   overflow-hidden items-center justify-center  gap-2 md:gap-6'>
    <div className=' flex h-full  w-full   '>
     <Image id='idHeroImg' quality={60} width={1000} height={1000} alt='developpeur web Montreal' src='/img/pic1.jpg' className='heroImg  object-center   object-cover  h-full opacity-100 md:opacity-100  w-full  ' />
    </div>
   </div>

   <div className='w-1/2  items-center justify-center h-full hidden md:flex'>
    <div className='absolute xg-gray-900 md:relative w-min flex items-center justify-center flex-col   '>
     <div className=' xg-orange-800   flex  items-end justify-center  w-min h-full     '>
      <p id='idHeroText1' className={` xg-red-900   md:leading-[6vw]    vertical-text text-center items-center justify-center flex w-full  pb-1 sm:text-[3vw]  md:text-[4vw]   lg:text-[6vw]  `}>
       {'SENIOR'}
      </p>
      <div className=' w-full xg-red-500 leading-none '>
       <p id='idHeroText2' className=' xg-blue-700 single-line w-min   text-main  sm:text-[5vw] md:text-[4vw] lg:text-[5vw]   items-start text-start      '>
        {langContext == 'en' ? '3D WEB' : 'WEB 3D'}
       </p>
       <p id='idHeroText3' className={`xg-orange-700  w-min  items-start text-start    ${sizeTextDesktop}   `}>
        {langContext == 'en' ? ' DEVELOPER' : 'DÉVELOPPEUR'}
       </p>
      </div>
     </div>
     <p id='idHeroText4' className=' xg-orange-700 xg-red-500 text-[3vw] w-full  xs:text-[2vw] sm:text-[2vw] md:text-[1.2vw] lg:text-[0.75vw] items-end text-end text-main  '>
      PORTFOLIO
     </p>
    </div>
   </div>

   <div className='  absolute md:hidden flex-row text-white font-poppins  items-end justify-center  bottom-5  flex  w-full  h-full   '>
    <div className='absolute md:relative w-min flex items-center justify-center flex-col xg-red-500    '>
     <div className=' xg-orange-800   flex  items-end justify-center  w-min h-full    '>
      <p id='idHeroMobileText1' className={` xg-red-900   leading-[12vw]    vertical-text text-center items-center justify-center flex w-full  pb-1 text-[13vw]     `}>
       {'SENIOR'}
      </p>
      <div className=' w-full xg-red-500 leading-none '>
       <p id='idHeroMobileText2' className=' xg-blue-700 single-line w-min  text-main    items-start text-start    text-[12.5vw]   '>
        {langContext == 'en' ? '3D WEB ' : 'WEB 3D'}
       </p>
       <p id='idHeroMobileText3' className={`xg-orange-700  w-min    items-start text-start     ${sizeTextMobile}   `}>
        {langContext == 'en' ? ' DEVELOPER' : 'DÉVELOPPEUR'}
       </p>
      </div>
     </div>
     <p id='idHeroMobileText4' className='  xg-red-500  w-full  items-end text-end text-main text-[3vw] sm:text-[2vw] '>
      PORTFOLIO
     </p>
    </div>
   </div>
  </section>
 );
}

export default Hero;
