import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../components/context/AppContext';

function Pourcentage() {
 const { pageNumber } = useContext(AppContext);

 // //console.log(" :", getComputedStyle(document.documentElement).getPropertyValue("--afterPourcentage-height"));

 useEffect(() => {
  document.documentElement.style.setProperty('--afterPourcentage-height', `${(pageNumber - 1) * 20}px`);

  if (pageNumber === 1) {
   document.documentElement.style.setProperty('--Pourcentage-visibility', `hidden`);
  } else document.documentElement.style.setProperty('--Pourcentage-visibility', `visible`);
 }, [pageNumber]);

 return (
  <div
   id='idPource'
   className='mainContent data-scroll-section  pourcentage-countainer fixed hidden  md:flex flex-col items-end justify-center  mix-blend-difference    min-h-[100px] h-screen  w-full
         text-hp9  font-light font-poppins'
  >
   <div className='flex items-center justify-center flex-col px-[6vw] xs:px-[6vw] sm:px-[4vw] sm:pt-[5vh] md:px-[4vw]  lg:px-[2vw]  xl:px-[1.75vw]  bl:px-[2vw]'>
    <div
     className={`py-[1vh] textPourcentage hidden
         transition-color  font-light  ease-in-out rotate-90 
         text-center
         min-w-[6vw] sm:min-w-[4vw] md:min-w-[2vw]  `}
    >
     0{pageNumber - 1}
    </div>
    <div className={`pourcentage`}></div>
    <div className={`textPourcentage hidden rotate-90`}>F</div>
   </div>
  </div>
 );
}

export default Pourcentage;
