import { useEffect, useRef, useContext } from 'react';
import Image from 'next/image';
import gsap from 'gsap';

import AppContext from '../../components/context/AppContext';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Exteriorz() {
 const scroller = useRef();
 const skills = useRef();
 const { langContext, setPageNumber } = useContext(AppContext);

 function getScrollAmount() {
  const extElements = document.querySelector('#extElements');
  let racesWidth = extElements.scrollWidth;
  return -(racesWidth - window.innerWidth);
 }

 useEffect(() => {
  const ctx = gsap.context(() => {
   const extElements = document.querySelector('#extElements');
   const tween = gsap.to(extElements, { x: () => getScrollAmount(), duration: 3, ease: 'none' });

   ScrollTrigger.create({
    trigger: '.extWrap',
    start: 'top 0%',
    end: () => `+=${getScrollAmount() * -1}`,
    pin: true,
    animation: tween,
    scrub: 1,
    invalidateOnRefresh: true,
    markers: false,
   });
  });
  return () => ctx.revert();
 }, []);

 const optionsExt = {
  root: null,
  rootMargin: '0px',
  threshold: 0.9,
 };

 useEffect(() => {
  let observerExt = new IntersectionObserver(entries => {
   // //console.log("extRef :", entries[0].isIntersecting);
   if (entries[0].isIntersecting) {
    setPageNumber(6);
   }
  }, optionsExt);
  observerExt.observe(scroller.current);
 }, []);

 return (
  <div id='idExterior'>
   <div className='extWrap bg-white ' ref={scroller}>
    <div id='extElements' className='extElements'>
     <div id='skills' className=' flex overflow-x-hidden text-white w-[500vw] m-0 xg-black relative min-h-[400px] hScreen '>
      <section ref={skills} className='skill-set xg-green-800 flex w-full min-h-[400px] hScreen bg-transparent ns-horizontal-section__item items-center justify-center z-50'>
       <div className='bg-backbg g-red-500 w-full md:w-1/2 h-full flex justify-center items-center flex-col px-[8vw] '>
        <h2 id='idExteriorText' className='flex flex-col xg-blue-800 w-full md:w-fit justify-center text-start items-start md:text-start max-w-full pb-[6vh]  md:pb-[0vh]'>
         <span className=' text-hp3 w-full text-main leading-[0vh] py-[1em]'>{langContext == 'en' ? '3D RENDER' : 'EXTÉRIEUR'}</span>
         <span className=' text-hp3 w-full text-main leading-[0vh] pb-[0.5em]'>{langContext == 'en' ? '& INTERIOR' : '& INTÉRIEUR'}</span>

         <p className='xg-red-800 flex items-center gap-[1rem] justify-start text-hp9 tracking-[0.1vw] xg-blue-800 w-full  font-poppins py-[1rem]  '>
          {langContext == 'en' ? '3D RENDERING' : 'RENDU 3D'}
          <span className='w-[30px] h-[2px]  flex bg-white'></span>
          <span className='text-hp9 '>3DSMAX & CORONA </span>
         </p>
        </h2>
        <div className='xg-orange-500 flex  md:hidden w-full h-[35vh] md:h-full max-h-[1000px]  '>
         <Image width={1000} height={1000} quality={20} alt='création site web montreal' src='/img/pic (9).jpg' className='   bg-[#eeefee]  object-cover   z-50   h-full   items-center justify-center' />
        </div>
       </div>
       <div className='xg-white hidden md:flex md:w-1/2 h-full text-black justify-center items-center flex-col'>
        <div className='xg-red-500 w-[60%] h-[80vh] flex flex-col items-start justify-center'>
         <div className='xg-red-800 text-white font-inter pb-[4vh]'>
          <p className='text-backbg text-hp9 font-light '>{langContext == 'en' ? 'Cozy Sofa Chair' : 'Fauteuil Douillet'}</p>
          <p className='text-gray-500 text-hp10 font-light'>{langContext == 'en' ? '3D Image Concept' : "Concept d'image 3D"}</p>
         </div>
         <div className='xg-orange-500 w-full h-full max-h-[1000px]'>
          <Image width={1000} height={1000} quality={10} alt='developpeur web' src='/img/pic (9).jpg' className='  bg-[#eeefee]   object-cover   z-50      items-center justify-center' />
         </div>
        </div>
       </div>
      </section>

      <section ref={skills} className='skill-set xg-white xg-green-600 flex w-full min-h-[400px] hScreen bg-transparent ns-horizontal-section__item items-center justify-center z-50'>
       <div className='text-white hidden w-full h-full absolute textSlider md:flex justify-end items-start pb-[6vh] pl-[6vw] flex-col'>
        <p className='text-hp9 font-light'>{langContext == 'en' ? 'Bedroom Vue' : 'Chambre à Coucher'}</p>
        <p className='text-hp10 font-light'>{langContext == 'en' ? 'Interior Rendering' : 'RENDU INTÉRIEUR'}</p>
       </div>
       <div className='hidden  md:flex h-full md:w-2/6 xg-red-500 '>
        <Image width={1000} height={1000} quality={20} alt='developpement web 3D' src='/img/pic (2).jpg' className='  bg-[#eeefee]   object-cover   z-50  w-full  h-full   items-center justify-center' />
       </div>
       <div className=' w-full h-full md:w-4/6 flex flex-col items-center justify-center '>
        <div className=' w-[85%] h-[75vh] flex flex-col items-center justify-center   '>
         <div className='xg-red-800 text-white font-inter pb-[4vh] w-full'>
          <p className='text-backbg text-hp9 font-light'>{langContext == 'en' ? 'Interior Vue' : 'Vue Intérieur '}</p>
          <p className='text-gray-500 text-hp10 font-light'>{langContext == 'en' ? '3D Rendering' : 'Rendu 3D'}</p>
         </div>
         <div className='xg-orange-500  w-full h-full max-h-[1000px]'>
          <Image width={1000} height={1000} quality={20} alt='developpeur informatique' src='/img/m1.jpg' className=' bg-[#eeefee]    object-cover   z-50   h-full   items-center justify-center' />
         </div>
        </div>
       </div>
      </section>

      <section ref={skills} className='skill-set xg-white xg-green-600 flex w-full min-h-[400px] hScreen bg-transparent ns-horizontal-section__item items-center justify-center z-50'>
       <div className='text-white hidden w-full h-full absolute textSlider md:flex justify-end items-start pb-[6vh] pl-[6vw] flex-col'></div>
       <div className='hidden  md:flex h-full md:w-2/6 xg-red-500 '>
        <Image width={1000} height={1000} quality={20} alt='freelance web montreal' src='/img/ab1.jpg' className='  bg-[#eeefee]   object-cover   z-50  w-full  h-full   items-center justify-center' />
       </div>
       <div className=' w-full h-full md:w-4/6 flex flex-col items-center justify-center '>
        <div className=' w-[85%] h-[75vh] flex flex-col items-center justify-center   '>
         <div className='xg-red-800 text-white font-inter pb-[4vh] w-full'>
          <p className='text-backbg text-hp9 font-light'>{langContext == 'en' ? 'Living Room ' : 'Salon'}</p>
          <p className='text-gray-500 text-hp10 font-light'>{langContext == 'en' ? '3D Interior Rendering' : 'Rendu 3D INTÉRIEUR'}</p>
         </div>
         <div className='xg-orange-500  w-full h-full max-h-[1000px]'>
          <Image width={1000} height={1000} quality={20} alt='freelance web jamiviz' src='/img/pic (0).jpg' className='  bg-[#eeefee]   object-cover   z-50   h-full   items-center justify-center' />
         </div>
        </div>
       </div>
      </section>

      <section ref={skills} className='skill-set xg-green-600 flex w-full min-h-[400px] hScreen bg-transparent ns-horizontal-section__item items-center justify-center z-50'>
       <div className=' g-blue-500 w-full  flex   xg-white  h-full md:w-2/6 md:flex flex-col items-center justify-center '>
        <div className='g-orange-500 w-[85%] h-[75vh] md:w-[60%] md:h-[60vh] flex flex-col items-center justify-center   '>
         <div className='g-red-800 text-white font-inter pb-[4vh] w-full'>
          <p className='text-backbg text-hp9 '>{langContext == 'en' ? 'Kitchen Vue' : 'Vue Cuisine'}</p>
          <p className='text-gray-500 text-hp10 font-light'>{langContext == 'en' ? '3D Interior Rendering' : 'Rendu 3D INTÉRIEUR'}</p>
         </div>
         <div className='xg-orange-500  max-h-[1000px] xg-orange-500 w-full  h-full '>
          <Image width={1000} height={1000} quality={20} alt='freelance web jamiviz' src='/img/pic (4).jpg' className='  bg-[#eeefee]   object-cover   z-50   h-full   items-center justify-center' />
         </div>
        </div>
       </div>
       <div className=' hidden  w-full  h-full md:flex md:w-4/6 xg-red-500 '>
        <Image width={1000} height={1000} quality={20} alt='freelance web jamiviz' src='/img/pic (1).jpg' className='  bg-[#eeefee]   object-cover   z-50  w-full  h-full   items-center justify-center' />
       </div>
      </section>

      <section ref={skills} className='skill-set xg-white flex w-full min-h-[400px] hScreen bg-transparent ns-horizontal-section__item items-center justify-center z-50'>
       <div className=' w-full h-full flex flex-col items-center justify-center '>
        <div className=' g-orange-500 w-[85%] h-[75vh] md:h-[65vh] flex flex-col items-center justify-center   '>
         <div className='g-red-800 text-white font-inter pb-[4vh] w-full'>
          <p className='text-backbg text-hp9 '>{langContext == 'en' ? 'Bedroom Vue' : 'Chambre à Coucher'}</p>
          <p className='text-gray-500 text-hp10 font-light'>{langContext == 'en' ? '3D Interior Rendering' : 'Rendu 3D INTÉRIEUR'}</p>
         </div>
         <div className='g-orange-500 w-full h-full  max-h-[1000px]'>
          <Image width={1000} height={1000} quality={20} alt='freelance web jamiviz' src='/img/pic (3).jpg' className='  bg-[#eeefee]   object-cover w-full   z-50   h-full   items-center justify-center' />
         </div>
        </div>
       </div>
      </section>
     </div>
    </div>
   </div>
  </div>
 );
}

export default Exteriorz;
