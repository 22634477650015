import { useEffect, useRef, useContext } from 'react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';
import AppContext from '../../components/context/AppContext';
import Link from 'next/link';

gsap.registerPlugin(ScrollTrigger);

function Split() {
 const TreedRef = useRef();
 const { setPageNumber, langContext } = useContext(AppContext);
 const optionsTreed = {
  root: null,
  rootMargin: '0px',
  threshold: 0.05,
 };

 useEffect(() => {
  let observerTreed = new IntersectionObserver(entries => {
   // //console.log("extRef :", entries[0].isIntersecting);
   if (entries[0].isIntersecting) {
    setPageNumber(7);
   }
  }, optionsTreed);
  observerTreed.observe(TreedRef.current);
 }, []);

 useEffect(() => {
  const details = gsap.utils.toArray('.desktopContentSection:not(:first-child)');
  const photos = gsap.utils.toArray('.desktopPhoto:not(:first-child)');

  gsap.set(photos, { yPercent: 101 });

  const allPhotos = gsap.utils.toArray('.desktopPhoto');

  // create
  let mm = gsap.matchMedia();

  // add a media query. When it matches, the associated function will run
  mm.add('(min-width: 600px)', () => {
   // this setup code only runs when viewport is at least 600px wide
   //console.log("desktop");

   const ctx = gsap.context(() => {
    ScrollTrigger.create({
     trigger: '.gallery',
     start: 'top top',
     end: 'bottom bottom',
     pin: '.right',
    });

    //create scrolltrigger for each details section
    //trigger photo animation when headline of each details section
    //reaches 80% of window height
    details.forEach((detail, index) => {
     let headline = detail.querySelector('h3');
     let animation = gsap.timeline().to(photos[index], { yPercent: 0 }).set(allPhotos[index], { autoAlpha: 0 });
     ScrollTrigger.create({
      trigger: headline,
      start: 'top 80%',
      end: 'top 50%',
      animation: animation,
      scrub: 1,
      markers: false,
     });
    });
   });
   return () => ctx.revert();
  });

  return () => mm.revert();
 }, []);

 return (
  <div id='idInteractive' className='gallery pb-20 bg-backbg text-white transition-all duration-300 ease-in-out idSpliterIndex' ref={TreedRef}>
   <div className='left font-poppins  text-center'>
    <div className='desktopContent'>
     <div className='desktopContentSection flex items-center ' id='slipDektopText1'>
      <h2 className=' text-[5.5vw] sm:text-[5vw] md:text-[4vw] w-full text-main leading-[0vh] py-[1em]'>{langContext == 'en' ? '3D WEB' : 'DÉVELOPPEMENT'}</h2>
      <h2 className=' text-[5.5vw] sm:text-[5vw] md:text-[4vw] w-full text-main leading-[0vh] pb-[1em] '>{langContext == 'en' ? 'DEVELOPEMENT' : 'WEB 3D'}</h2>
      <p className='xg-red-800  gap-2 text-hp6 md:flex hidden tracking-[0.1vw] xg-blue-800 w-full leading-[0vh] pb-[4em]  items-center justify-center  font-poppins  '>
       {langContext == 'en' ? 'INTERACTIVE 3D' : '3D INTERACTIVE'}
       <span className='w-[30px] h-[2px]   bg-white'></span>
       <span className='text-hp9 '> THREEJS </span>
      </p>
      <p className='text-hp9 max-w-md text-center'>
       {langContext == 'en'
        ? `3D web development is revolutionizing web development by introducing immersive experiences. It has an impact on 
                the fields of architecture and e-commerce. This technology enables the creation of captivating websites, incorporating 
                3D elements for an exceptional user interaction. Visitors can explore virtual 
                architectural models and engage in more immersive online experiences, thus opening up new opportunities for web developers.`
        : `Le developpement web 3D révolutionne le développement web en introduisant des expériences immersives .
                 Il impacte les domaines de l'architecture et du e-commerce. Cette technologie permet la création 
                 de sites web captivants, intégrant des éléments 3D pour une interaction utilisateur exceptionnelle. Les visiteurs peuvent 
                  parcourir des modèles architecturaux virtuels et vivre des expériences en ligne plus engageantes, ouvrant ainsi de nouvelles opportunités pour les développeurs web.`}
      </p>
     </div>

     <div className='desktopContentSection gap-8  flex items-center' id='slipDektopText2'>
      <h3 className='text-main text-hp5'>{langContext == 'en' ? `DESIGN & 3D` : `DESIGN et 3D`} </h3>
      <p className='text-hp9 max-w-md'>
       {langContext == 'en'
        ? `As an experienced web developer, I proficiently utilize tools like Next.js, React, Three.js, Node.js to fully 
                harness web 3D and enhance the user experience. Whether in the field of marketing or e-commerce, interactive 3D elements,
                 combined with technology expertise, significantly boost engagement and facilitate understanding. This innovative approach
                  provides exciting opportunities to create interactive websites.`
        : `En tant que développeur web expérimenté, je maîtrise des outils tels que Next.js, React, Three.js, Node.js 
                pour exploiter pleinement la web 3D et enrichir l'expérience utilisateur. Que ce soit dans le domaine du marketing
                 ou du e-commerce, les éléments 3D interactifs, associés à ma maîtrise des technologies, renforcent considérablement l'engagement
                 et facilitent la compréhension. Cette approche innovante offre des opportunités passionnantes pour créer des sites web interactifs.`}
      </p>
     </div>
     <div className='desktopContentSection gap-8  flex items-center' id='slipDektopText3'>
      <h3 className='text-main text-hp5'>{langContext == 'en' ? ` Optimization and accessibility` : `Optimisation et Accessibilité`}</h3>
      <p className='text-hp9 max-w-md'>
       {langContext == 'en'
        ? `Mastery of 3D web development demands a solid understanding of interaction design, performance optimization, and accessibility.
                 A well-crafted portfolio showcases the successful balance
                 between visual aesthetics and functionality, enabling the creation of immersive and user-friendly 3D environments. This is a crucial approach for successful web development.`
        : `La maîtrise du développement web en 3D exige une solide expertise en design d'interaction,
                 en optimisation des performances et en accessibilité. Un portfolio bien conçu reflète
                 l'équilibre entre esthétique visuelle et fonctionnalité, permettant de développer des sites web 
                  3D immersifs et conviviaux. C'est une approche cruciale pour un développement web réussi.`}
      </p>
      <Link
       href={{
        pathname: `${process.env.NEXT_PUBLIC_origin}/interactive`,
       }}
       className=' hidden md:block bg-mainDarkText buttonsStyleDark hover:bg-mainDarkText/70  items-center   w-44 px-6  justify-center py-2 my-2 text-white text-hp10'
      >
       {langContext == 'en' ? 'See more' : 'Voir Plus'}
      </Link>
     </div>
    </div>
   </div>

   <div className='right'>
    <div className='mobileContent text-start pt-20'>
     <h2 id='splitGT1' className=' text-hp3 w-full text-main leading-[0vh]  py-[1em]'>
      {langContext == 'en' ? '3D WEB' : 'DEVELOPPEMENT'}
     </h2>
     <h2 id='splitGT2' className=' text-hp3 w-full text-main leading-[0vh] pb-[1em] md:pb-[0.75em]'>
      {langContext == 'en' ? 'DEVELOPEMENT' : 'WEB 3D'}
     </h2>
     <p id='splitGT3' className='bxg-red-800 text-hp6 tracking-[0.1vw] xg-blue-800 w-full   flex items-center  md:pb-[4em] font-poppins  '>
      {langContext == 'en' ? 'INTERACTIVE 3D' : '3D INTERACTIVE'}
      <span className='w-[30px] h-[2px] mx-[0.5rem]   bg-white'></span>
      <span className='text-hp9  '> THREEJS </span>
     </p>
     <div className='mobilePhoto red  ' id='splitimg1' role='img' aria-label='freelance web montreal'></div>
     <h3 id='splitTitre1' className='text-xl pt-10 pb-6 px-2 flex items-center w-full justify-start  text-main'>
      {langContext == 'en' ? `UX & 3D` : `UX et 3D`}
     </h3>
     <p id='splitText1' className='text-sm px-2 pb-6'>
      {langContext == 'en'
       ? `3D web development revolutionizes web development by introducing immersive experiences. 
              It has a significant impact on the fields of architecture and e-commerce. This technology 
              enables the creation of captivating websites, incorporating 3D elements for an exceptional user interaction.
               Visitors can explore virtual architectural models and engage in more immersive online experiences, thus opening up new opportunities for web developers.`
       : `Le développement web en 3D révolutionne la création de sites en introduisant des expériences immersives.
              Cette technologie a un impact majeur sur les domaines de l'architecture et du e-commerce. Elle permet la 
              conception de sites captivants, intégrant des éléments 3D pour une interaction utilisateur exceptionnelle.
               Les visiteurs peuvent explorer des modèles architecturaux virtuels et vivre des expériences en ligne plus engageantes,
              ouvrant ainsi de nouvelles perspectives pour les développeurs web.`}
     </p>
     <div className='mobilePhoto  pink' id='splitimg2' role='img' aria-label='developpeur web montreal'></div>
     <h3 id='splitTitre2' className='text-xl pt-10 pb-6 px-2 flex items-center w-full justify-start  text-main'>
      {langContext == 'en' ? `Interactive 3D` : `3D Interactive `}
     </h3>
     <p id='splitText2' className='text-sm px-2 pb-6'>
      {langContext == 'en'
       ? `As an experienced web developer, I have a strong expertise in using tools such as Next.js, React, Three.js, 
              and Node.js to fully leverage web 3D and enhance the user experience. Whether in the field of marketing or e-commerce, 
              the integration of interactive 3D elements, combined with my technology mastery, significantly enhances user engagement
               and facilitates understanding. This innovative approach provides exciting opportunities for creating interactive websites.`
       : `En tant que développeur web expérimenté, je possède une expertise solide dans l'utilisation 
              d'outils tels que Next.js, React, Three.js et Node.js pour exploiter pleinement la web 3D et améliorer 
              l'expérience utilisateur. Que ce soit dans le domaine du marketing ou du e-commerce, l'intégration d'éléments 
              3D interactifs, combinée à ma maîtrise des technologies, améliore considérablement 
              l'engagement des utilisateurs et facilite leur compréhension. Cette approche novatrice offre 
              des possibilités passionnantes pour la création de sites web interactifs.`}
     </p>

     <div className='mobilePhoto blue' id='splitimg3' role='img' aria-label='developpement web montreal'></div>
     <h3 id='splitTitre3' className='text-xl pt-10 pb-6 px-2 flex items-center w-full justify-start  text-main'>
      {langContext == 'en' ? `Improvement and Performance` : `Amélioration et Performance`}
     </h3>
     <p id='splitText3' className='text-sm px-2 pb-6'>
      {langContext == 'en'
       ? `Mastery of 3D web development demands a strong expertise in interaction design, performance optimization, 
              and accessibility. A well-crafted portfolio reflects the balance between visual 
              aesthetics and functionality, enabling the development of immersive and user-friendly 3D websites. This approach is crucial for successful web development.`
       : `La maîtrise du développement web en 3D requiert une solide expertise en design d'interaction, en optimisation des
               performances et en accessibilité. Un portfolio soigneusement conçu reflète l'équilibre entre esthétique visuelle et fonctionnalité,
               permettant ainsi la création de sites web 3D immersifs et conviviaux. Cette approche est essentielle pour garantir le succès du développement web.`}
     </p>
     <div className='w-ful flex items-center justify-start px-2 pt-6'>
      <Link
       href={{
        pathname: `${process.env.NEXT_PUBLIC_origin}/interactive`,
       }}
       className=' flex bg-mainDarkText buttonsStyleDark hover:bg-mainDarkText/70      items-center   text-hp10
							text-center   w-44  justify-around px-2 py-4  text-white text-[3vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs tracking-[02px]
							'
      >
       {langContext == 'en' ? 'See more' : 'Voir Plus'}
      </Link>
     </div>
    </div>

    <div className='desktopPhotos' role='img' aria-label='developpeur web montreal'>
     <div className='desktopPhoto red' role='img' aria-label=' creation site web Montreal'></div>
     <div className='desktopPhoto pink' role='img' aria-label='developpement web montreal'></div>
     <div className='desktopPhoto blue' role='img' aria-label='freelance web montreal'></div>
    </div>
   </div>
  </div>
 );
}

export default Split;

/* id="splitimg1"  id="splitTitre1" id="splitTitre1"*/
