import React from 'react';
import {useContext} from 'react';
import AppContext from '../../components/context/AppContext';
import Image from 'next/image';

function Brand() {
	const {langContext} = useContext(AppContext);

	return (
		<section
			id='idBrandSection'
			className='mainContent data-scroll-section bg-backbg min-h-max px-[10vw] pb-[8vh] md:pb-16 idPartnerIndex tracking-wide'
		>
			<div className='flex justify-center items-start md:items-center flex-col   pt-[12vh]  md:pt-[20vh] md:pb-[14vh]   w-full  min-h-max'>
				<p id='idBrandMobile' className='  text-hp3 text-start md:text-center w-full text-main   '>
					{langContext == 'en' ? ' Partnerships and Collaborations' : ' Partenariats et Collaborations'}
				</p>
				<div className='xg-green-300 flex flex-col  md:flex-row  w-full  justify-center items-center pt-[2vh] md:pt-[8vh] px-[24vw] md:px-[20vw]  bg-ornge-500 gap-2 md:gap-12 '>
					<div id='idBrandimg1' className='xg-blue-500 min-w-[100px]'>
						<Image
							width={500}
							height={500}
							quality={20}
							alt='collab1'
							src='/brand/yamcap.png'
							className='  object-cover w-full o opacity-80 0 md:hover:scale-110 duration-500 transition-transform  '
						/>
					</div>

					<div id='idBrandimg2' className='xg-blue-500  min-w-[100px]'>
						<Image
							width={500}
							height={500}
							quality={20}
							alt='collab2'
							src='/brand/valor.png'
							className='  object-cover w-full  opacity-90 md:hover:scale-110 duration-500 transition-transforme   '
						/>
					</div>
					<div id='idBrandimg3' className='xg-blue-500  min-w-[100px]  '>
						<Image
							width={500}
							height={500}
							quality={20}
							alt='collab3'
							src='/brand/siyam.png'
							className='  object-cover w-full   opacity-80 md:hover:scale-110 duration-500 transition-transform  '
						/>
					</div>

					<div id='idBrandimg4' className='xg-blue-500  min-w-[100px] '>
						<Image
							width={500}
							height={500}
							quality={20}
							alt='collab4'
							src='/brand/ynstudio.png'
							className='  object-cover w-full   opacity-80 md:hover:scale-110 duration-500 transition-transform  '
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Brand;
