import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image from 'next/image';
import AppContext from '../context/AppContext';
import { MdArrowForward } from 'react-icons/md';
import useIsMobile from '../hooks/useIsMobile';
gsap.registerPlugin(ScrollTrigger);

import { reactix, nextjsx, tailwindx, nodejsx, typescrpitx, htmlx, gsapx, threejsx } from './abc';

import Icons from './icons';

function Web({}) {
 const { setPageNumber, langContext } = useContext(AppContext);

 const webpart = useRef(null);

 const webOne = useRef(null);
 const webTwo = useRef(null);
 const webThree = useRef(null);
 const webFour = useRef(null);

 const [active, setActive] = useState('');

 useEffect(() => {
  if (!webpart.current || !webOne.current || !webTwo.current || !webThree.current | !webFour.current) return;

  const stackpartsOne = [...document.querySelectorAll('.stackpartsOne')];

  let ctx = gsap.context(() => {
   const animCard1 = gsap.timeline({
    scrollTrigger: {
     trigger: webpart.current,

     start: window.matchMedia('(max-width: 767px)').matches == true ? '0% 80%' : 'top 100%',
     end: window.matchMedia('(max-width: 767px)').matches == true ? '45% 80%' : '60% 100%',

     scrub: 3,
     markers: false,
    },
   });

   animCard1.fromTo(webOne.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'power4.inOut' }, '>');

   if (stackpartsOne.length > 0) {
    animCard1.fromTo(stackpartsOne, { y: 30, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.05, ease: 'power4.inOut' }, '<');
   }

   animCard1.fromTo(webThree.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'power4.inOut' }, '>');
   animCard1.fromTo(webFour.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: 'power4.inOut' }, '<');
  });
  return () => ctx.revert();
 }, []);

 const setActiveProject = proj => () => {
  setActive(proj);
 };

 const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.95,
 };
 const options3 = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
 };

 useEffect(() => {
  let observerSkillTwo = new IntersectionObserver(entries => {
   if (entries[0].isIntersecting) setPageNumber(5);
  }, options3);
  observerSkillTwo.observe(webpart.current);
 }, []);

 const abc = useCallback(
  (title, text, imgz, proj, role) => {
   return (
    <div
     onMouseEnter={setActiveProject(proj)}
     onMouseLeave={setActiveProject(null)}
     className={`bgs-green-500 h-full md:gap-[1rem] gap-[1rem] py-[0.1rem] md:py-[0rem] group
   flex-col cursor-pointer md:hover:w-3/4  md:w-1/4  w-full flex xbg-gray-500  transition-all duration-500 ease-in-out  items-start justify-start md:justify-center `}
    >
     <div className={`   justify-start    relative md:h-[400px] h-[200px] flex w-full  items-center transition-all duration-500 ease-in-out  overflow-hidden  `}>
      <Image
       fill={true}
       quality={20}
       alt='creation site web Montreal'
       src={imgz}
       className={` object-cover  opacity-50
      transition-all duration-500 ease-in-out
      w-full    h-full     `}
      />

      <div
       className={`w-full h-full text  absolute  flex items-center justify-center flex-col font-bold text-center transition-all duration-500 ease-in-out
       
      bg-[#1C3144]/95   text-black left-0`}
      >
       <div className={`   md:text-[1.2rem] xl:text-[1.2rem] w-full max-w-[10rem] transition-all duration-50 ease-in-out text-white uppercase`}>{title}</div>

       <div className='flex  items-center justify-start gap-[1rem] pb-[1rem] text-main text-[12px] md:text-xs  '>
        <p className=''>{text}</p>
       </div>

       <div className='  w-[3rem] h-[3rem] flex items-center justify-center text-center text-[12px] rounded-full bg-white text-main'>
        <p>{langContext == 'fr' ? 'Voir ' : 'View'} </p>
       </div>
      </div>

      <div className={`flex w-full h-full absolute   transition-all duration-500 ease-in-out  ${active === proj ? 'opacity-100 top-[0%]' : 'opacity-80 top-[100%]'}  `}>
       <Image
        fill={true}
        quality={20}
        alt='creation site web Montreal'
        src={imgz}
        className={` object-cover relative 
     
      w-full    h-full     `}
       />
      </div>
     </div>

     <div
      className={`flex  w-full items-center text-center justify-center gap-[0.2rem]    transition-all duration-500 ease-in-out 
        
        ${active === proj ? 'opacity-100 ' : 'opacity-0'} 
      flex-col text-[12px] `}
     >
      <p className='w-full text-white'>{langContext == 'fr' ? 'ROLES DU PROJET ' : 'ROLES IN PROJECT '} </p>
      <p className='w-full  pt-[0.1rem] md:w-max md:px-[0.6rem] text-main '>{role}</p>
     </div>
    </div>
   );
  },
  [active]
 );

 //prettier-ignore
 return (
  <section className='bg-red-700x mainContent  md:gap-[2rem]  min-h-[600px] maxx-h-[1000px] ml:min-h-[1200px] h-full left-0 right-0   w-full text tracking-wide  flex items-center justify-center flex-col ' ref={webpart}>
   <div className='bg-backbg g-red-500 w-full md:w-1/2 h-full flex justify-center items-center flex-col px-[8vw] bxg-red-500 pt-[6rem]  ' ref={webOne}>
    <h2 id='' className='flex flex-col xg-blue-800 w-full md:w-fit justify-center text-start items-start md:text-center max-w-full pb-[6vh]  md:pb-[0vh]'>
     <span className=' text-hp3 w-full text-main leading-[0vh] py-[1.25em]'>{langContext == 'fr' ? 'Développement' : 'Full Stack'}</span>
     <span className=' text-hp3 w-full text-main leading-[0vh] pb-[0.2em]'>{langContext == 'fr' ? 'Full Stack' : 'Development'}</span>
    </h2>
    <p className='xg-red-800  gap-2 text-hp10 md:flex flex tracking-[0.1vw]  xg-blue-800 w-full leading-[0vh] pb-[3rem] md:pt-[2.5rem] ml:pt-[8rem] items-center justify-start md:justify-center  font-poppins uppercase'>
     <span className='text-hp9 '>Web</span>

     <span className='w-[30px] h-[2px]  flex bg-white'></span>

     {langContext == 'en' ? ' SEO Implementation' : 'Implementation SEO '}
    </p>
   </div>

   <div className='xbg-blue-300 w-full pb-[2rem] gap-[2rem] md:gap-[1rem] flex flex-col items-center justify-center h-full px-[8vw]' ref={webTwo}>
    <div className='flex w-full gap-[2rem] xs:gap-[2rem] md:gap-[4rem] items-center justify-start md:justify-center'>
        <div><Icons icon={reactix()} text='reactjs' size={3} /></div>
        <div><Icons icon={nextjsx()} text='nextjs' size={3} /></div>
        <div><Icons icon={tailwindx()} text='tailwind' size={3} /></div>
        <div className='hidden md:flex'><Icons icon={nodejsx()} text='' size={3} /></div>
    </div>

    <div className='flex w-full gap-[2rem] xs:gap-[2rem] md:gap-[4rem] items-center justify-start md:justify-center'>
        <div><Icons icon={typescrpitx()} text='typescript' size={3} /></div>
        <div className='hidden md:flex'><Icons icon={htmlx()} text='html5' size={3} /></div>
        <div><Icons icon={gsapx()} text='GSAP' size={3} /></div>
        <div><Icons icon={threejsx()} text='Threejs' size={3} /></div>
    </div>
</div>

   <div id='xx' className='min-h-max hidden  xbg-red-500 px-[8vw] pt-[2rem] h-full ] md:pt-[0rem] gap-[1rem]  md:px-[2rem] bg-red-800  lg:px-[8vw] bl:px-[10vw] items-center justify-start md:flex md:gap-[1rem] bgx-black  w-full md:justify-center bgs-red-500  ' ref={webFour}>
    <p className='bg-red-800x  gap-2 text-hp10 md:flex flex tracking-[0.1vw]  xbg-blue-800 w-full leading-[0vh] pb-[1rem] md:pb-[0rem]  items-center justify-start md:justify-start  font-poppins uppercase'>{langContext == 'en' ? ' Recents Projects' : 'Projets Récents '}</p>
   </div>

   <div id='xx' className='min-h-max px-[8vw] pt-[2rem] md:pt-[0.5rem] h-full md:pb-[8rem]  gap-[1rem]  md:px-[2rem] bg-red-800x lg:px-[8vw] bl:px-[10vw] grid grid-cols-1  justify-start md:flex md:gap-[1rem] bgx-black  w-full md:justify-center bgs-red-500  ' ref={webThree}>
    
   <p className='bg-red-800x  md:hidden gap-2 text-hp10 flex  tracking-[0.1vw]  g-blue-800 w-full leading-[0vh] pb-[1rem]  items-center justify-start md:justify-center  font-poppins uppercase'>{langContext == 'en' ? ' Recents Projects' : 'Projets Récents '}</p>
   
    {abc('Mavefa', 'Real Estate APP', '/img/web5.webp', 'proj1', 'Backend - 3D - SEO')}
    {abc('Gourmet V', 'E-Restaurant', '/img/web3.webp', 'proj2', 'FullStack - SEO')}
    {abc('Canican', 'Audio Web APP', '/img/web2.webp', 'proj3', 'FullStack - 3D - SEO')}
    {abc('LiliArts', 'Artist Portfolio', '/img/web6.webp', 'proj4', 'Frontend - SEO')}
   </div>
  </section>
 );
}

export default Web;
